<template>
  <ApolloQuery
    :query="require('@/graphql/queries/clientSupportRequests.gql')"
  >
    <template slot-scope="{ result: { loading, error, data } }">
      <div v-if="loading" class="messages__loading">Идёт загрузка данных...</div>
      <div v-if="error" class="messages apollo error">{{ error.message }}</div>
      <div class="lsg-faq">
        <div class="main__header">
          <div class="main__header__item left"></div>
          <div class="main__header__item">
            <h1>{{ title }}</h1>
          </div>
          <div class="main__header__item right"></div>
        </div>
        <div class="main__button">
          <router-link :to="{ name: 'question' }" class="btn">
            <i>
              <PlusIco/>
            </i>
            <span>ОБРАЩЕНИЕ</span>
          </router-link>
        </div>
        <div v-if="!loading && !error" class="main__content main__content--appeal">
          <div :class="{'main__appeals--full': $store.state.isEmpty}" class="main__appeals">
            <vue-good-table
              max-height="600px"
              :columns="columns"
              :rows="transformData(data ? data.clientSupportRequests : [])"
              @on-row-click="onRowClick"
              compactMode
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'lastQuestion'" class="truncate-text">{{ props.row.lastQuestion }}</span>
                <span v-else-if="props.column.field == 'status' && props.row.status == 'Закрыт'">
                  <span class="tag">{{props.row.status}}</span>
                </span>
                <span v-else-if="props.column.field == 'status' && props.row.status == 'Открыт'">
                  <span class="tag tag--green">{{props.row.status}}</span>
                </span>
                <span v-else>{{props.formattedRow[props.column.field]}}</span>
              </template>
              <div slot="emptystate" style="display: flex; justify-content: center;">
                У вас ещё нет обращений в поддержку
              </div>
            </vue-good-table>
          </div>
          <div v-if="$store.state.isEmpty === false" class="main__request">
            <SupportRequest
              v-for="(item, index) in [selectedRequest]"
              v-bind:status.sync="item.status"
              :key="index"
              :id="item.id"
              :client="item.client_id"
              :manager="item.manager_id"
              v-bind:questionAndAnswer.sync="item.qa_list"
              :registrationDate="item.created_date"
              :lastDate="item.last_date"
              :theme="item.theme.name"
              :role="'client'"
              :selectedRequest="selectedRequest"
            />
          </div>
        </div>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import SupportRequest from '@/components/ui/SupportRequest'
import PlusIco from '@/components/svg/plus'

export default {
  name: 'Support',
  components: {
    SupportRequest,
    PlusIco
  },
  beforeCreate () {
    this.$store.state.isEmpty = true
  },
  data () {
    return {
      title: 'Мои обращения',
      columns: [
        // {
        //   label: 'Приоритет',
        //   field: 'priority',
        //   type: 'number',
        //   width: '120px'
        // },
        {
          label: 'Последний вопрос',
          field: 'lastQuestion'
        },
        {
          label: 'Дата создания',
          field: 'created_date',
          width: '150px'
        },
        {
          label: 'Статус',
          field: 'status',
          width: '150px'
        }
      ],
      sortedRequest: [],
      trasformedRequest: [],
      selectedRequest: {}
    }
  },
  methods: {
    transformData (value) {
      if (value.length !== 0) {
        value.forEach(element => {
          element.priority = element.theme.priority
          element.qa_list = element.questions
          element.lastQuestion = element.qa_list[element.qa_list.length - 1].question
          element.created_date = element.created_date.split('T')[0].split('-').reverse().join('.')
        })
      }
      this.trasformedRequest = value
      return value
    },
    onRowClick (event) {
      this.trasformedRequest.forEach((element) => {
        if (element.id === event.row.id) {
          this.selectedRequest = element
        }
      })
      this.$store.state.isEmpty = false
    }
  }
}
</script>

<style lang="stylus">

.truncate-text {
  overflow hidden
  display -webkit-box
  -webkit-line-clamp 3
  -webkit-box-orient vertical
  line-height 1.3em
  height 3.9em
}
.main {
  &__content--appeal {
    display flex
    flex-direction row

    +below(1150px) {
      flex-direction column-reverse
    }
  }

  &__appeals {
    transition width 1s
    width 50%

    &--full {
      transition width 1s
      width 100%
    }
  }
  &__request {
    width 50%
    padding 0 20px
  }
  &__button {
    margin-top -55px
    padding 0px 20px 20px 20px
    display flex
    flex-direction row
    justify-content flex-end

    +below(600px) {
      display none
    }
  }

  +below(1150px) {
    &__appeals {
      width 100%
    }
    &__request {
      width 100%
    }
  }
}

</style>
